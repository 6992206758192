import { DeepPartial } from '@trpc/server';
import { DateTime } from 'luxon';
import { Property, PropertyArea, PropertySEO, PropertyStatus, TransactionType } from './property';
import { ServiceArea } from './service-area';

export const RESIDENCE_INDEXES = [
  'relevance',
  'price-asc',
  'price-desc'
] as const;

export type ResidenceIndex = typeof RESIDENCE_INDEXES[number];

export const RESIDENCE_TYPES = [
  'Residential Apartment'
] as const;
export type ResidenceType = typeof RESIDENCE_TYPES[number];

export const BHK_VALUES = [
  1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5
] as const;
export type BHK = typeof BHK_VALUES[number];

export const GARAGE_AVAILABILITY = [
  'Yes (Incl)',
  'Yes (Excl)',
  'No'
] as const;
export type GarageAvailability = typeof GARAGE_AVAILABILITY[number];

export const AMENITIES = new Map<string, string>([
  ['Acupressure Center', 'acupressure.png'],
  ['Amphitheater', 'theater.png'],
  ['Backup Power', 'battery.png'],
  ['Badminton Court', 'badminton.png'],
  ['Basketball Court', 'basketball.png'],
  ['Booster Pump', 'booster-pump.svg'],
  ['Cafeteria', 'coffee-shop.png'],
  ['CCTV Camera', 'cctv-camera.svg'],
  ['Club House', 'night-club.png'],
  ['Daycare Center', 'daycare-center.png'],
  ['Door Video Phone', 'door-video-phone.svg'],
  ['Firefighting System', 'extinguisher.png'],
  ['Garbage Disposal', 'garbage-disposal.png'],
  ['Garden/Park', 'park.svg'],
  ['Grocery Store', 'grocery-store.png'],
  ['Gym', 'dumbbell.svg'],
  ['Indoor Games', 'chess.png'],
  ['Intercom', 'intercom.svg'],
  ['Jacuzzi', 'jacuzzi.png'],
  ['Jogging Track', 'jogging.svg'],
  ['Mini Theater', 'cinema.png'],
  ['Open Dining Space', 'outdoor-table.png'],
  ['Party Hall', 'wine-glass-mountain.png'],
  ['Party Lawn', 'outdoor-decoration.png'],
  ['Piped Gas Connection', 'gas-pipe.png'],
  ['Playground', 'playground.svg'],
  ['Rain Water Harvesting', 'rain-water-harvesting.svg'],
  ['Salon', 'salon.png'],
  ['Security', 'policeman.png'],
  ['Skating Rink', 'skating.png'],
  ['Spa', 'spa.png'],
  ['Sports Facility', 'stadium.svg'],
  ['Swimming Pool', 'swimming-pool.svg'],
  ['Tennis Court', 'tennis.svg'],
  ['Vaastu Compliant', 'swastika.png'],
  ['Visitor Parking', 'parking-area.svg'],
  ['Volleyball Court', 'volleyball.png'],
  ['Waste Treatment Plant', 'waste-treatment.svg'],
  ['Water Purifier', 'water-filter.svg'],
  ['Yoga/Meditation Area', 'yoga.png']
]);

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface Residence extends Property {
  type: ResidenceType | null;
  variants: ResidenceVariant[];
  amenities: string[];
  salientFeatures: string[];
  featured: boolean;
  rankScore: number | null;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface ResidenceVariant {
  label: string | null;
  status: PropertyStatus | null;
  transactionType: TransactionType | null;
  bedrooms: BHK | null;
  bathrooms: number | null;
  balconies: number | null;
  garage: GarageAvailability | null;
  area: PropertyArea;
  price: number | null;
  possession: DateTime | null;
  bookingAmt: string | null;
  floorPlan: string | null;
}

export interface ResidenceAlgoliaEntry {
  objectID: string;
  id: string;

  // from residence
  propertyId: string;
  path: string;
  searchablePath: string[];
  label: string;
  type: ResidenceType | null;
  featured: boolean;
  rankScore: number | null;
  published: boolean;
  seo: PropertySEO;
  images: string[];

  // from variant
  variantIndex: number;
  status: PropertyStatus | null;
  transactionType: TransactionType | null;
  bedrooms: BHK | null;
  bathrooms: number | null;
  balconies: number | null;
  area: PropertyArea;
  price: number | null;
  possession: DateTime | null;

  // data of sibling records
  others: {
    price: number | null,
    area: number | null,
    bedrooms: BHK | null;
  }[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class Residence {

  public static toAlgolia(residence: Residence) {
    const others: ResidenceAlgoliaEntry['others'] = residence.variants.map(v => ({
      area: v.area.sbu,
      bedrooms: v.bedrooms,
      price: v.price
    }));

    return residence.variants.map((v, index): ResidenceAlgoliaEntry => ({
      objectID: Residence.algoliaObjectId(residence.id, index),
      id: residence.id,

      propertyId: residence.propertyId,
      path: residence.path,
      searchablePath: [residence.propertyId, ...ServiceArea.seoToNames(residence.seo.areas).flat()],
      label: residence.label,
      type: residence.type,
      featured: residence.featured,
      rankScore: residence.rankScore,
      published: residence.published,
      seo: residence.seo,
      images: residence.images,

      variantIndex: index,
      status: v.status,
      transactionType: v.transactionType,
      bedrooms: v.bedrooms,
      bathrooms: v.bathrooms,
      balconies: v.balconies,
      area: v.area,
      price: v.price,
      possession: v.possession,

      others
    }));
  }

  public static fromAlgolia(hit: ResidenceAlgoliaEntry) {
    return {
      id: hit.id,
      propertyId: hit.propertyId,
      path: hit.path,
      label: hit.label,
      images: hit.images,
      type: hit.type,

      featured: hit.featured,
      published: hit.published,
      rankScore: hit.rankScore,
      seo: hit.seo,

      variants: hit.others.map(o => ({
        area: {
          sbu: o.area
        },
        bedrooms: o.bedrooms,
        price: o.price
      }))

    } satisfies DeepPartial<Residence>;
  }

  public static algoliaObjectId(id: string, variantIndex: number) {
    return `${id}-${variantIndex}`;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class ResidenceVariant {
  public static label(label: string | null, index: number) {
    return label || `Type ${index + 1}`;
  }
}

export type ResidenceSearchOutput = ReturnType<typeof Residence['fromAlgolia']>;